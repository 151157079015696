import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { Story } from 'core/models/story-manager';
import { Link } from 'react-router-dom';
import { SoundbiteIconExtraSmall } from '@naan/icons/soundbite-icon';
import { learnStorySlugPath } from 'components/nav/path-helpers';
import classNames from 'classnames';

import __ from '@core/lib/localization';

const Wrapper = styled(Link, {
  $$color: '$colors$orange-500',
  all: 'unset',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  // gap: 4,
  color: '$$color',
  textStyle: 'small-text-bold',
  '&.completed': {
    $$color: '$colors$textSecondary',
  },
  '&.disabled': {
    $$color: '$colors$gray-200',
    cursor: 'default',
  },
});

const SoundbitesBadge = ({
  label,
  to,
  completed,
  disabled,
}: {
  to: string;
  label: React.ReactNode;
  completed?: boolean;
  disabled?: boolean;
}) => {
  return (
    <Wrapper
      to={to}
      className={classNames('soundbites-badge', {
        completed,
        disabled,
      })}
    >
      <SoundbiteIconExtraSmall />
      {label}
    </Wrapper>
  );
};

export const SoundbitesClassroomBadge = ({
  story,
  labeled = false,
}: {
  story: Story;
  labeled?: boolean;
}) => {
  if (!story.hasVisibleSoundbites) {
    return null;
  }

  return (
    <SoundbitesBadge
      to={learnStorySlugPath(story.slug) + '?soundbites=1'}
      completed={story.allSoundbitesCompleted}
      label={
        <>
          {story.visibleSoundbiteCount?.toString() || '0'}
          {labeled ? ' ' + __('Soundbites', 'soundbites') : null}
        </>
      }
    ></SoundbitesBadge>
  );
};

export const SoundbitesStoryBadge = ({
  story,
  labeled = false,
}: {
  story: Story;
  labeled?: boolean;
}) => {
  if (!story.hasVisibleSoundbites) {
    return null;
  }

  return (
    <SoundbitesBadge
      to={learnStorySlugPath(story.slug) + '?soundbites=1'}
      completed={story.allSoundbitesCompleted}
      label={
        <>
          {story.soundbiteDisplayProgress()}
          {labeled ? ' ' + __('Soundbites', 'soundbites') : null}
        </>
      }
    ></SoundbitesBadge>
  );
};
