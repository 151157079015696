import * as React from 'react';
import {
  ContentGrid,
  // ContentGridInnerContainer,
} from 'components/global-layout';
import { PurchasedCoupons } from '../components/gift-coupons/purchased-coupons';
// import { Ecoupon } from '../components/gift-coupons/e-coupon';
import { PageHeading } from 'components/page-heading';
import { CheckoutResultTarget } from '../components/account/checkout-result-target';
import { useTitle } from '../common/hooks/use-title';
import { appConfig } from 'app/env';
import { styled } from 'naan/stitches.config';
import { PlainMarkdownWithLinks } from 'naan/primitives/text';
// import { VSpacer } from 'naan/primitives/spacer';
// import { CouponCodeForm } from 'components/gift-coupons/coupon-code-form';
import { LargeGap } from '@naan/primitives/spacer/gaps';
import { AppFactory } from 'app/app-factory';
// import { CouponAppliedDialog } from 'components/gift-coupons/coupon-applied-dialog';

import { CouponCodeFormWidget } from 'components/gift-coupons/coupon-code-form-widget';
import __ from 'core/lib/localization';
import { Navigate } from 'react-router-dom';
import { signInPath } from 'components/nav/path-helpers';
import { observer } from 'mobx-react';
// import { ExternalLink } from '@naan/primitives/text/external-link';

// const CouponsWrapper = styled('div', {
//   paddingTop: '56px',
//   '& > * + * ': {
//     marginTop: '24px',
//     '@medium': {
//       marginTop: '0px',
//     },
//   },
//   '@medium': {
//     display: 'grid',
//     gridTemplateColumns: 'repeat(12,1fr)',
//     columnGap: '24px',
//     '& > div:first-child': {
//       gridColumn: '3 / span 4',
//     },
//     '& > div': {
//       gridColumn: 'span 4',
//     },
//   },
// });

const MiddleColumn = styled('section', {
  gridColumn: '1/-1',
});

// const PurchasePrompt = styled('p', {
//   '@large': {
//     maxWidth: `${(100 / 12) * 8}%`,
//   },
// });

const CopyWrapper = styled('div', {
  $$spaceBetween: '$space$8',
  padding: 24,
  backgroundColor: '$colors$gray-25',
  borderRadius: 12,
  '& h3': {
    textStyle: 'small-heading',
    marginBottom: '$space$4',
  },
  '& a': {
    color: '$textPrimary',
    textDecoration: 'underline',
  },
  '& > div + div': {
    paddingTop: '$$spaceBetween',
  },

  '@large': {
    $$spaceBetween: 0,
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: 24,
  },
});

const GiftCouponsForm = () => {
  return (
    <>
      <CouponCodeFormWidget
        title={__('Redeem a gift coupon', 'redeemAGiftCoupon')}
        placeholder={__('Coupon code', 'couponCode')}
        buttonLabel={__('Redeem', 'redeem')}
      />
      <LargeGap />
    </>
  );
};

export const GiftCouponsScreen = observer(() => {
  useTitle(__('Gift coupons', 'giftCoupons'));

  const { userManager } = AppFactory.root;
  const { authenticated, accountData } = userManager;

  if (!authenticated) {
    const after = window.location.pathname;
    return <Navigate to={signInPath({ after })} replace />;
  }

  const { purchasedCoupons = [] } = accountData;

  return (
    <ContentGrid>
      <MiddleColumn>
        <PageHeading title={__('Gift coupons', 'giftCoupons')} />
        <GiftCouponsForm />

        <PurchasedCoupons coupons={purchasedCoupons} />
        {/* <Text textStyle="medium-heading">
          {__('Purchase a gift coupon', 'purchaseAGiftCoupon')}
        </Text>
        <VSpacer size={4} />
        <PurchasePrompt>
          {__(
            "Know someone who's studying Spanish at the intermediate level? Help them get fluent faster with a Jiveworld e-coupon.",
            'knowSomeoneWhosStudyingSpanishAt'
          )}
        </PurchasePrompt>
        <CouponsWrapper className="coupons">
          {giftPlans.map((plan: any) => (
            <Ecoupon plan={plan} key={plan.slug} />
          ))}
        </CouponsWrapper> */}
        <LargeGap />
        <CopyWrapper>
          <div>
            <h3>{__('How do gift coupons work?', 'howDoGiftCouponsWork')}</h3>
            <p>
              {__(
                "After you buy a gift coupon you will receive an email with the coupon code and instructions of how to redeem. The coupon code will also be listed at the top of this page, and you'll be able to see when it is redeemed, and by whom.",
                'afterYouBuyAGiftCoupon'
              )}
            </p>
            <p>
              {__(
                'The recipient will create their account and redeem the coupon on this website.',
                'theRecipientWillCreateTheirAccount'
              )}
            </p>
          </div>
          <div>
            <h3>{__('Any questions?', 'anyQuestions')}</h3>
            {/*<p>
              We're here to help,{' '}
              <ExternalLink
                href={appConfig<>('website.urls.support')}
                // rel="noreferrer noopener"
              >
                {' '}
                {/ * @armando, was is the significance of 'noopener' here, and would we want everyplace? * /}
                visit our help center
              </ExternalLink>
              .
            </p>*/}

            <PlainMarkdownWithLinks
              source={__(
                "We're here to help, [visit our help center](%{url}).",
                'wereHereToHelpLink',
                { url: appConfig.website.urls.support }
              )}
            />
          </div>
        </CopyWrapper>
        <LargeGap />
        <CheckoutResultTarget />
      </MiddleColumn>
    </ContentGrid>
  );
});
